import React from "react";

import PrivacyPolicyContent from "../organisms/privacy-policy-content";
import Layout from "../layout/Layout";
import Topbar from "../molecules/topbar";
import Seo from "../layout/Seo";

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Topbar isSolid />
      <PrivacyPolicyContent />
    </Layout>
  );
};

export default PrivacyPolicy;

export const Head = () => <Seo title="Blockbit.es | Privacy Policy" />;
